<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="questions">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> تعداد کل : </span>
              <span class="mx-1">{{ totalCount }}</span>
            </b-col>

            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mx-50 w-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Field: productCategoryId -->
            <b-col v-if="categories !== null"
                   cols="12"
                   md="6"
            >
              <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>دسته بندی:</label>
                <v-select
                    v-model="categoryId"
                    :options="categories"
                    label="name"
                    :reduce="name => name.productCategoryId"
                    :clearable="false"
                    class="w-75 mx-1"
                    input-id="productCategoryId"
                />
              </div>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mt-2"
            >
              <b-button
                  variant="success"
                  class=""
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="downloadFile"
              >
                دانلود اکسل لیست
              </b-button>
            </b-col>

            <!-- Search -->
            <!--            <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--            >-->
            <!--              <div class="d-flex align-items-center justify-content-end">-->
            <!--                <b-form-input-->
            <!--                    v-model="searchCommand"-->
            <!--                    class="d-inline-block mr-1"-->
            <!--                    placeholder="جستجوی شماره موبایل ..."-->
            <!--                />-->
            <!--              </div>-->
            <!--            </b-col>-->
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="questions"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >
          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{ data.item.createDate.slice(11, 16) }}
              -
              {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
            </span>
          </template>

          <!-- Column: level -->
          <template #cell(level)="data">
            <span v-if="data.item.level === 1"> آسان </span>
            <span v-if="data.item.level === 2"> متوسط </span>
            <span v-if="data.item.level === 3"> سخت </span>
          </template>

          <!-- Column: Edit -->
          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-exam-question-edit', params: { id: data.item.id } }" class="cursor-pointer">
              <feather-icon size="20" icon="EditIcon"/>
            </b-link>
          </template>

          <!-- Column: delete -->
          <template #cell(delete)="data">
            <span v-b-modal.modal-delete @click="showModal(data.item.id)" class="cursor-pointer">
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteQuestion()"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {NumberGetAllRequest} from "@/libs/Api/Number";
import {ConsultantRequestGet} from "@/libs/Api/ConsultantRequest";
import {
  ExamDeleteRequest,
  ExamGetquestionsRequest,
  ExamQuestionDeleteRequest,
  ExamQuestionGetQuestionsRequest
} from "@/libs/Api/Exam";
import {ProductCategoryGetAllCourseCategoriesRequest} from "@/libs/Api/Product";
import {getUserData} from "@/auth/utils";
import {saveAs} from 'file-saver';
import axiosIns from "@/libs/axios";

export default {
  name: "QuestionList",
  title: "لیست پرسش‌ها - پنل ادمین مکس",
  data() {
    return {
      questions: null,
      showOverlay: false,
      totalCount: null,
      currentPage: 1,
      categories: null,
      deleteItem: null,
      perPage: 10,
      pageNumber: 1,
      count: 10,
      searchCommand: '',
      categoryId: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'question', label: 'متن پرسش'},
        {key: 'categoryName', label: 'دسته بندی'},
        {key: 'level', label: 'سطح'},
        {key: 'createDate', label: 'تاریخ ثبت'},
        {key: 'edit', label: 'ویرایش'},
        {key: 'delete', label: 'حذف'},
      ],
      defaultCreateData: {
        isDeleted: false,
        productCategoryId: 0,
        parentId: 0,
        name: ''
      },
    }
  },
  async created() {
    await this.getAllQuestions(this.pageNumber, this.count, this.categoryId);
    await this.getAllCourseCategories();
  },
  methods: {
    async getAllQuestions(pageNumber, count, categoryId) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        page: pageNumber,
        size: count,
        categoryId: categoryId
      }

      let examQuestionGetQuestionsRequest = new ExamQuestionGetQuestionsRequest(_this);
      examQuestionGetQuestionsRequest.setParams(data);
      await examQuestionGetQuestionsRequest.fetch(function (content) {
        _this.questions = content.data.data;
        _this.totalCount = content.data.totalCount;
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async getAllCourseCategories() {
      let _this = this;
      _this.overlay = true;
      let defaultGetData = {
        pageNumber: 0,
        count: 0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetAllCourseCategoriesRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await productCategoryGetAllProductCategoriesRequest.fetch(function (content) {
        _this.overlay = false;
        _this.categories = content.productCategories;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async downloadFile() {
      let _this = this;
      _this.showOverlay = true;
      let userToken = getUserData().data.token;

      await axiosIns.post(`ExamQuestion/GetExcel`, null, {
        params:{
          productId:this.productId,
          state:this.perState
        },
        responseType: 'blob',
        'Authorization': userToken,
      }).then(resp => {
        _this.showOverlay = false;
        saveAs(resp.data, 'لیست پرسش‌ها.xlsx');
      })
          .catch((error) => {
            _this.showOverlay = false;
            console.log(error);
          });
    },
    async deleteQuestion() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        id: _this.deleteItem
      }

      let examQuestionDeleteRequest = new ExamQuestionDeleteRequest(_this);
      examQuestionDeleteRequest.setParams(data);
      await examQuestionDeleteRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کوپن حذف شد.`,
          },
        })
        _this.getAllQuestions(_this.pageNumber, _this.count , _this.categoryId)
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getAllQuestions(nv, this.count, this.categoryId);
    },
    categoryId: function (nv, ov) {
      this.getAllQuestions(this.currentPage, this.count, nv);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>